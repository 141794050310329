<template>
    <div class="desktopLayoutClass">
        <v-app>
            <!-- authErrorFlag Dialog Start -->
            <div justify="center">
                <v-dialog v-model="authErrorFlag" persistent max-width="290">
                    <v-card>
                        <v-card-title style="word-break:normal">{{ $t("Warning_Message.Session_Expired_Please_Login_Again") }}</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" depressed @click="closeAuthErrorDialog">{{ $t("Warning_Message.Login") }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
            <!-- authErrorFlag Dialog End -->

            <!-- errorFlag Dialog Start -->
            <div justify="center">
                <v-dialog v-model="errorFlag" persistent max-width="290">
                    <v-card>
                        <v-card-title style="word-break:normal">
                            {{ $t("Warning_Message.Something_Went_Wrong") }}
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn depressed
                                color="primary" @click="errorFlag = false">
                                {{$t("Warning_Message.Ok")}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                </div>
            <!-- errorFlag Dialog End -->

            <!-- restartAppFlag Dialig Start -->
            <div>
                <v-row justify="center">
                    <v-dialog v-model="restartAppFlag" persistent max-width="290">
                        <v-card>
                            <v-card-title style="word-break:normal">
                                {{ $t("Warning_Message.Restart_App") }}
                            </v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn depressed color="primary" @click="restartAppFlag = false;">
                                    {{ $t("Warning_Message.Ok") }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </div>
            <!-- restartAppFlag Dialog End -->

            <!-- Header Start -->
            <div v-show="showHeaderFlag" class="d-flex header" style="padding-top: 10px; padding-left: 5px;">
                <div class="d-flex" style="width: 100%">
                    <div style="width: 10%; padding-top: 6px" align="left" v-on:click="redirectToHomePage">
                        <img src="https://img.icons8.com/material-outlined/20/000000/left.png"/>
                    </div>

                    <div style="width: 90%" align="left">
                        <span class="headertext">{{ $t("Customer.AllSpecializationsPage.Specialities") }}</span>
                    </div>
                </div>
            </div>
            <!-- Header End -->
    
            <div class="pa-4" v-if="isLoading">
                <div class="d-flex flex-wrap justify-space-between align-center mt-2 pa-1">
                    <div style="width: 33%;" v-for="i in 12" :key="i">
                        <v-skeleton-loader class="pa-1" type="image" width="110px" height="150px"></v-skeleton-loader>
                    </div>
                </div>
            </div>
            <div class="pa-4" v-else>
                <div class="d-flex flex-wrap justify-space-between align-center mt-2 pa-1">
                    <div class="pa-1" style="width: 33%;" v-for="specializationData in specializationsList" :key="specializationData.specialization_key">
                        <specializationSubComponent :specialization_data="specializationData" :pageName="pageName"></specializationSubComponent>
                    </div>

                    <div v-for="i in extradivs" :key="i">
                        <specializationSubComponent :specialization_data="emptySpecializationData" :pageName="pageName"></specializationSubComponent>
                    </div>
                </div>
            </div>
        </v-app>
    </div>
</template>

<script>
import * as heatmap from 'heatmap.js';
import axios from 'axios';

export default ({
    name: 'SpecializationsPage',
    components: {
        specializationSubComponent: () => import('../../../components/specializationSubComponent.vue')
    },
    data() {
        return {
            token: '',
            showHeaderFlag: true,
            isLoading: true,
            authErrorFlag: false,
            errorFlag: false,
            restartAppFlag: false,
            specializationsList: [],
            specializations: [],
            extradivs: 0,
            emptySpecializationData: {},
            loggenInFlag: false,
            pageName: 'allSpecializationPage'
        }
    },
    mounted() {
        // Get customer token.
        this.token = this.$cookies.get('customerToken');

        // HeatMap.
        this.$el.addEventListener('click', this.onClick);

        // User Agent.
        let userAgent = navigator.userAgent;
        if(userAgent.includes('kbzpay')){
            this.showHeaderFlag = false;
        }

        // Document Title.
        if(window.xm){
            document.title = '';
        } else {
            if(this.$store.state.locale == 'en') {
                document.title = 'Specializations';
            } else {
                document.title = 'Specializations';
            }
        }

        this.isMobile = this.$device.mobile;
        this.$i18n.locale = this.$store.state.locale;

        if (!this.token) {
            this.$i18n.locale = 'mm';
            this.$store.dispatch("changeLocale", 'mm');
            this.loggenInFlag = false;
        }
        // Call allSpecializationsPage API.
        let allSpecializationsPageRequest = {
            token: this.token ? this.token : '',
            typeOfUser: 'CUSTOMER'
        };

        axios.post(process.env.VUE_APP_BACKEND_URL + '/allSpecializationsPage', allSpecializationsPageRequest)
        .then((allSpecializationsPageResponse) => {
            this.specializationsList = allSpecializationsPageResponse.data.data;
            this.isLoading = false;
            this.loggenInFlag = true;

            if (!this.specializationsList.length % 3 !== 0) {
                this.extradivs = 3 - this.specializationsList.length % 3;
                this.emptySpecializationData = {empty_div: true}
            }
            localStorage.setItem('bookingType', 'regularAppointment');
            this.userPageViewTable();
        })
        .catch((allSpecializationsPageError) => {
            if (allSpecializationsPageError.response && allSpecializationsPageError.response.status === 401) {
                this.authErrorFlag = true;
            } else if (allSpecializationsPageError.response && allSpecializationsPageError.response.status === 400) {
                if (window.xm || userAgent.includes('kbzpay')) {
                    this.restartAppFlag = true;
                } else {
                    this.errorFlag = true;
                }
            } else {
                this.errorFlag = true;
            }
        });
    },
    methods: {
        redirectToHomePage() {
            this.$router.go(-1);
        },
        closeAuthErrorDialog() {
            this.authErrorFlag = false;
            this.$router.push({
                name: "Login",
            });
        },
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                pageName : 'specializationsPage',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'specializationsPage',
                typeOfUser: 'CUSTOMER',
                token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
    },
    }
});
</script>

<style>
.header {
  display: block;
  box-shadow: 0px 2px 15px 0px #00000026;
  padding: 16px 20px !important;
  background-color: white;
}
.desktopLayoutClass {
    max-width: 425px;
}

.headertext{
    font-size: 18px;
    font-weight: bold;
}

.heading{
    font-size: 14px;
    font-weight: bold;
}

.subheading{
    font-size: 14px;
}

.normaltext{
    font-size: 12px;
}
</style>
